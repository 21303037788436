import React, {  useEffect } from "react";

import { useWebSocketContext } from "../../contexts/websocketContext";
import { DisplayMapClass } from "../map/HereMap";
import { useSettings } from "../../contexts/settingsContext";

export const Eventboard = () => {
    const { sendMessage, eventMessage } = useWebSocketContext();
    const {currentSettings} = useSettings();

    const getData = () => {
        console.log("Fetch event data");
        sendMessage(`{"action":"eventfetch", "filter_text":"${currentSettings.map.eventFilter.value}","filter_parameter":"pub_id","fetch_external":"${currentSettings.map.fetchExternalData.value}"}`)
    }
    useEffect(() => {
        getData();
        const timerId = setInterval(() => {
            getData()
        }, currentSettings.map.eventFetcher.value * 1000);
        return () => clearInterval(timerId);
      }, []);


    return (
        <>
        <div>
            Currently {typeof eventMessage === "undefined" || eventMessage === null ? 0: eventMessage.length} events available and active.
        </div>
        <div className="component-wrapper">
            <DisplayMapClass events={eventMessage}/>
        </div>
        <div className="component-wrapper">
            <table className="sdds-table sdds-table--compact">
            <thead className="sdds-table__header">
                <tr className="sdds-table__row">
                <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Index</th>
                <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Station ID</th>
                <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Geohash</th>
                <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Speed km/h</th>
                <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Publisher</th>
                </tr>
            </thead>
            <tbody class="sdds-table__body">
                {eventMessage ? (
                eventMessage.map((message, idx) => (
                    <tr className="sdds-table__row" key={idx}>
                    <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{idx}</td>
                    <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{message ? message.station_id : " "}</td>
                    <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{message ? message.geohash : " "}</td>
                    <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{message ? (message.speed*3.6).toFixed(1) : " "}</td>
                    <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{message ? message.pub_id : " "}</td>
                    </tr>
                ))
                ) : (
                <tr></tr>
                )}
            </tbody>
            </table>
        </div>
        </>
    );
  };