import * as React from "react";

class Map extends React.Component {
  mapRef = React.createRef();

  state = {
    // The map instance to use during cleanup
    map: null,
  };

  componentDidMount() {
    const H = window.H;
    const platform = new H.service.Platform({
      apikey: "3-nRydfCHb7sp28Y5sL1xF3sC1hilD3-ar4NMGL0Fvs",
    });

    const defaultLayers = platform.createDefaultLayers();

    // Create an instance of the map
    const map = new H.Map(
      this.mapRef.current,
      defaultLayers.vector.normal.map,
      {
        // This map is centered over Europe
        center: { lat: this.props.position.lat, lng: this.props.position.lon },
        zoom: this.props.settings.mapZoomLevel.value,
        pixelRatio: window.devicePixelRatio || 1,
      }
    );

    this.setState({ map });
    const pointMarker = new H.map.Marker({
      lat: this.props.position.lat,
      lng: this.props.position.lon,
    });
    map.addObject(pointMarker);
  }

  componentWillUnmount() {
    // Cleanup after the map to avoid memory leaks when this component exits the page
    this.state.map.dispose();
  }

  render() {
    return (
      // Set a height on the map so it will display
      <div ref={this.mapRef} style={{ height: "500px" }} />
    );
  }
}

export default Map;
