import React, { useState, createContext, useContext, useEffect,useRef } from "react";
import pako from "pako";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useUserContext } from "./userContext";
import { useSettings } from "./settingsContext";
export const WebSocketContext = createContext(null);


export function WebSocketProvider(props) {
  const { accessToken } = useUserContext();
  const { currentSettings  } = useSettings();
  const Url = process.env.REACT_APP_WEBSOCKET_URL + "/?token=" + accessToken;
  const [messageHistory, setMessageHistory] = useState([]);
  const [eventMessage, setEventMessage] = useState(null);
  const [vehiclesMessage, setVehiclesMessage] = useState(null);
  const [simulatorMessage, setSimulatorMessage] = useState(null);
  const [warningMessage, setWarningMessage] = useState(null);
  const [mcsMessage, setMcsMessage] = useState(null);
  const vehiclesTempData = useRef([]);
  const eventsTempData = useRef([]);
  const simulatorTempData = useRef([]);
  const graphsTempData = useRef([]);
  const [snapMessage, setSnapMessage] = useState({
    id: 160403696,
    distance_in: 131.02446359844893,
    distance_out: 8.975536401551068,
    snap_road_coord: {
      lat: 59.18582766731454,
      lon: 17.643155978572118,
    },
    road_ref: "E 4;E 20",
    max_speed: 100,
    lanes: 2,
    one_way: "1",
  });
  const [graphsMessage, setGraphsMessage] = useState(null);
  const { sendMessage, lastMessage, readyState, lastJsonMessage } = useWebSocket(Url,{
    onOpen: () => console.log('opened'),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  useEffect(() => {
    const timerId = setInterval(() => {
      sendMessage('{"action":"sendmessage", "message":"hello world"}');
    }, 30 * 4 * 1000);
    return () => clearInterval(timerId);
  }, [sendMessage]);

  useEffect(() => {
    if (lastMessage !== null) {
      setMessageHistory(prev => [lastMessage].concat(prev.slice(0, currentSettings.connection.messagesInHistoryWS.value-1)));

      console.log("WebSocket: incoming", lastMessage);
    }
  }, [lastMessage, setMessageHistory,currentSettings.connection.messagesInHistoryWS]);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.hasOwnProperty('eventfetch')){
        console.log("Received list of events");
        // Decode base64 (convert ascii to binary)
        const strData = window.atob(lastJsonMessage.eventfetch);
        // Convert binary string to character-number array
        const charData = strData.split('').map(function(x) {
          return x.charCodeAt(0);
        });
        // Turn number array into byte-array
        const binData = new Uint8Array(charData);
        // Pako magic
        const data = pako.inflate(binData);
        // Convert gunzipped byteArray back to ascii string:
        const strOutData = String.fromCharCode.apply(null, new Uint16Array(data));
        if (lastJsonMessage.current_chunk===1){
          //it is the first data, put in in temp storage
          eventsTempData.current =JSON.parse(strOutData);
        }else{
          //add next chunkt to temp storage
          eventsTempData.current = eventsTempData.current.concat(JSON.parse(strOutData));
        }
        if (lastJsonMessage.current_chunk === lastJsonMessage.total_chunks && eventsTempData.current.length>0){
          //now all data has arrived, set it to the output value
          console.log("All event data received")
          setEventMessage(eventsTempData.current);
        }
      } else if (lastJsonMessage.hasOwnProperty('snap')){
        console.log("Received snap to road data");
        setSnapMessage(lastJsonMessage.snap);
      } else if (lastJsonMessage.hasOwnProperty("graphs")){
        console.log("Received graph data");
        if (lastJsonMessage.hasOwnProperty("current_chunk")){
          if (lastJsonMessage.current_chunk===1){
            //it is the first data, put in in temp storage
            graphsTempData.current =lastJsonMessage.graphs;
          }else{
            //add next chunkt to temp storage
            graphsTempData.current = graphsTempData.current.concat(lastJsonMessage.graphs);
          }
          if (lastJsonMessage.current_chunk === lastJsonMessage.total_chunks && graphsTempData.current.length>0){
            //now all data has arrived, set it to the output value
            console.log("All graphs data received")
            setGraphsMessage(graphsTempData.current);
          }
        } else {
          setGraphsMessage(lastJsonMessage.graphs)
        }
      } else if (lastJsonMessage.hasOwnProperty("vehicles")){
        console.log("Received vehicle data");
        // Decode base64 (convert ascii to binary)
        const strData = window.atob(lastJsonMessage.vehicles);
        //var strData = (lastJsonMessage.vehicles.toString('base64'));
        //var strData = Buffer.from(lastJsonMessage.vehicles, 'base64').toString('utf8')
        // Convert binary string to character-number array
        const charData = strData.split('').map(function(x) {
          return x.charCodeAt(0);
        });

        // Turn number array into byte-array
        const binData = new Uint8Array(charData);

        // Pako magic
        const data = pako.inflate(binData);
        // Convert gunzipped byteArray back to ascii string:
        const strOutData = String.fromCharCode.apply(null, new Uint16Array(data));
        if (lastJsonMessage.current_chunk===1){
          //it is the first data, put in in temp storage
          //setVehiclesTempData(JSON.parse(strOutData));
          vehiclesTempData.current =JSON.parse(strOutData);
        }else{
          //add next chunkt to temp storage
          //setVehiclesTempData(prev => prev.concat(JSON.parse(strOutData)));
          vehiclesTempData.current = vehiclesTempData.current.concat(JSON.parse(strOutData));
        }
        if (lastJsonMessage.current_chunk === lastJsonMessage.total_chunks && vehiclesTempData.current.length>0){
          //now all data has arrived, set it to the output value
          console.log("All vehicle data received")
          setVehiclesMessage(vehiclesTempData.current);
          //vehiclesTempData.current = [];
        }
      }
      else if (lastJsonMessage.hasOwnProperty('simulator')){
        console.log("Received list of simulator points");
        // Decode base64 (convert ascii to binary)
        const strData = window.atob(lastJsonMessage.simulator);
        // Convert binary string to character-number array
        const charData = strData.split('').map(function(x) {
          return x.charCodeAt(0);
        });
        // Turn number array into byte-array
        const binData = new Uint8Array(charData);
        // Pako magic
        const data = pako.inflate(binData);
        // Convert gunzipped byteArray back to ascii string:
        const strOutData = String.fromCharCode.apply(null, new Uint16Array(data));
        if (lastJsonMessage.current_chunk===1){
          //it is the first data, put in in temp storage
          simulatorTempData.current =JSON.parse(strOutData);
        }else{
          //add next chunkt to temp storage
          simulatorTempData.current = simulatorTempData.current.concat(JSON.parse(strOutData));
        }
        if (lastJsonMessage.current_chunk === lastJsonMessage.total_chunks && simulatorTempData.current.length>0){
          //now all data has arrived, set it to the output value
          console.log("All simulator data received")
          setSimulatorMessage(simulatorTempData.current);
        }
      }else if (lastJsonMessage.hasOwnProperty("warning")){
        console.log("Received warning");
        setWarningMessage(lastJsonMessage)
      }else if (lastJsonMessage.hasOwnProperty("mcs")){
        console.log("Received MCS data");
        setMcsMessage(lastJsonMessage.mcs);
      }
    }
  },[lastJsonMessage] )

  //useEffect(()=>{
  //  console.log("Restarting position sending");
  //  if (currentSettings.sendDataViaWS){
  //    const timerId2 = setInterval(()=>{
  //    console.log("Sending position message to backend " +JSON.stringify(positionValue.current) );

  //      sendMessage(`{"action":"position", "message":${JSON.stringify(positionValue.current)}}`)
  //    },currentSettings.sendInterval*1000);
  //  return () => clearInterval(timerId2);
  //  }

  //},[currentSettings.sendInterval,currentSettings.sendDataViaWS, sendMessage]);


  const contextValue = {
    sendMessage,
    readyState,
    connectionStatus,
    eventMessage,
    snapMessage,
    graphsMessage,
    vehiclesMessage,
    simulatorMessage,
    messageHistory,
    warningMessage,
    mcsMessage,
  };

  return (
    <WebSocketContext.Provider value={contextValue}>
      {props.children}
    </WebSocketContext.Provider>
  );
}

export function useWebSocketContext() {
  return useContext(WebSocketContext);
}
