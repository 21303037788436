import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
//import reportWebVitals from "./reportWebVitals";
import { defineCustomElements, addTheme } from "@scania/components";
import { theme as scania } from "@scania/theme-light";
import { UserProvider } from "./contexts/userContext";
import { SettingsProvider } from "./contexts/settingsContext";
import { PositionProvider } from "./contexts/positionContext";
import { WebSocketProvider } from "./contexts/websocketContext";
import { ResizeProvider } from "./contexts/resizeContext";

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <SettingsProvider>
        <WebSocketProvider>
          <PositionProvider>
            <ResizeProvider>
              <App />
            </ResizeProvider>
          </PositionProvider>
        </WebSocketProvider>
      </SettingsProvider>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

defineCustomElements();
addTheme(scania);
