import React from 'react';
import {useSettings} from "../../contexts/settingsContext"

function getObjProp(obj, propString) {
  if (!propString)
    return obj;

  var prop, props = propString.split('.');

  for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
    prop = props[i];

    var candidate = obj[prop];
    if (candidate !== undefined) {
      obj = candidate;
    } else {
      break;
    }
  }
  return obj[props[i]];
}

const updateObjProp = (obj, value, propPath) => {
    const [head, ...rest] = propPath.split('.');
    !rest.length
    ? obj[head] = value
    : this.updateObjProp(obj[head], value, rest.join('.'));
}

function leaf(obj, path, value) {
  const pList = path.split('.');
  const key = pList.pop();
  const pointer = pList.reduce((accumulator, currentValue) => {
    if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
    return accumulator[currentValue];
  }, obj);
  pointer[key] = value;
  return obj;
}

const SettingsParameter = ({name,path}) =>{
    const { currentSettings, setSettings} = useSettings();
    const handleChangeStr = (event)=>{
      let tmp = {...currentSettings}
      leaf(tmp, path+".value",event.target.value);
      setSettings(tmp)
  }
    const handleChange = (event)=>{
        let tmp = {...currentSettings}
        leaf(tmp, path+".value",parseFloat(event.target.value));
        setSettings(tmp)
    }
    const toggleChange = (event)=>{
        let tmp = {...currentSettings}
        leaf(tmp, path+".value",!getObjProp(currentSettings,path+".value"));
        setSettings(tmp)
    }
    const renderInput = ()=>{
        switch (getObjProp(currentSettings,path +".type")){
            case 'int':
            case 'float':
                return (
                <>
                {getObjProp(currentSettings,path+".text")}
                <input type="range" color="green" min={getObjProp(currentSettings,path +".min")} max={getObjProp(currentSettings,path +".max")} value={getObjProp(currentSettings,path +".value")} onChange={e => handleChange(e)} />
                {getObjProp(currentSettings,path+".value")} [{getObjProp(currentSettings,path+".unit")}]
                </>
                )
            case 'bool':
                return(
                <div class="sdds-toggle" tabindex="0">
                <input type="checkbox" class="sdds-toggle-input" id={getObjProp(currentSettings,path)} checked={getObjProp(currentSettings,path+".value")} onChange={(e) => toggleChange(e)}/>
                <span class="sdds-toggle-switch"></span>
                <label class="sdds-toggle-label" for={getObjProp(currentSettings,path)}>{getObjProp(currentSettings,path+".text")}</label>
                </div>
                )
            case 'str':
                return(
                <>
                <div>
                <sdds-textfield type="text" size="md" value={getObjProp(currentSettings,path+".value")} placeholder={getObjProp(currentSettings,path+".text")} onInput={e => handleChangeStr(e)} >
                <span slot="sdds-label">{getObjProp(currentSettings,path+".text")}</span>
                </sdds-textfield>
                </div>
                </>
                )
            default:
                return <><div>default</div></>
                //<Form.Control  key={getObjProp(currentSettings,path)+getObjProp(currentSettings,path+".value")} defaultValue={getObjProp(currentSettings,path +".value")} onChange={handleChange} />
        }
    }
    return(
        <div class="sdds-row">
          {
           //name+ " - "+path + "=> "+ getObjProp(currentSettings,path+".value")
            renderInput()
            }
        </div>




    )
}
export default SettingsParameter;