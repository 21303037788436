import React, { useEffect } from "react";
import { useUserContext } from "../../contexts/userContext";

const UserPage = () => {
  const { getUserName, handleLogout, getExpirationTime } = useUserContext();
  useEffect(() => {}, []);
  const expTime = new Date(getExpirationTime());

  const toStringWithTrailingZeros = (input)=>{
    return input < 10 ? "0"+input : input.toString()
  }
  const expString =
    expTime.getFullYear() +
    "-" +
    toStringWithTrailingZeros(expTime.getMonth()+1)+
    "-" +
    toStringWithTrailingZeros(expTime.getDate()) +
    " " +
    toStringWithTrailingZeros(expTime.getHours()) +
    ":" +
    toStringWithTrailingZeros(expTime.getMinutes())

  return (
    <>
      <div>User page</div>
      <div>Logged in as {getUserName()}</div>
      <div>Token will expire {expString}</div>
      <button onClick={handleLogout}>Log out</button>
    </>
  );
};

export default UserPage;
