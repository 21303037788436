// src/DisplayMapClass.js
import * as React from 'react';
import Geohash from "../../tools/Geohash";

export class DisplayMapClass extends React.Component {
  mapRef = React.createRef();
  state = {
    // The map instance to use during cleanup
    map: null,
    vehicle_layer: null,
    event_layer: null
  };

  shouldComponentUpdate(nextProps, nextState){
    if (JSON.stringify(this.props.vehicles) !== JSON.stringify(nextProps.vehicles) ||
      JSON.stringify(this.props.events) !== JSON.stringify(nextProps.events) ||
      JSON.stringify(this.props.route) !== JSON.stringify(nextProps.route) ||
      JSON.stringify(this.props.mypos) !== JSON.stringify(nextProps.mypos)||
      JSON.stringify(this.props.settings) !== JSON.stringify(nextProps.settings))
      {
      console.log("Events/vehicles are not similar, rerender map")
      return true;
    }
    if (this.state.map !== nextState.map){
      console.log("Map state is changing, rerender")
      if (this.state.map === null ){
        return true;
      }
    }
    return false;

/*    if (this.state.map === null && nextState.map !== null){
      return true;
    }
    if (this.state.map !== null){
      let obj = this.state.map.getObjects();
      let lay = this.state.map.getLayers();
      console.log("bla")

    }
    return true;
*/
  };

  componentDidMount() {
    const H = window.H;
    const platform = new H.service.Platform({
        apikey: "3-nRydfCHb7sp28Y5sL1xF3sC1hilD3-ar4NMGL0Fvs"
    });

    const defaultLayers = platform.createDefaultLayers();

    // Create an instance of the map
    const map = new H.Map(
      this.mapRef.current,
      defaultLayers.vector.normal.map,
      {
        // This map is centered over Europe
        center: { lat: 57.78, lng: 14.18 },
        zoom: 5,
        pixelRatio: window.devicePixelRatio || 1
      }
    );

    // MapEvents enables the event system
    // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
    // This variable is unused and is present for explanatory purposes
    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

    // Create the default UI components to allow the user to interact with them
    // This variable is unused
    const ui = H.ui.UI.createDefault(map, defaultLayers);

    this.setState({ map });

  }

  startClustering(){
    // First we need to create an array of DataPoint objects,
    // for the ClusterProvider
    const H = window.H;
    var dataPoints = this.props.vehicles.map((item)=>{
      const latlon = Geohash.decode(item.g);
      return new H.clustering.DataPoint(latlon.lat, latlon.lon);
    });

    // Create a clustering provider with custom options for clusterizing the input
    var clusteredDataProvider = new window.H.clustering.Provider(dataPoints, {
      clusteringOptions: {
        // Maximum radius of the neighbourhood
        eps: this.props.settings.map.clusteringEps.value,
        // minimum weight of points required to form a cluster
        minWeight: this.props.settings.map.clusteringMinWeight.value
      },
    });

    // Create a layer tha will consume objects from our clustering provider
    var clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider);

    // To make objects from clustering provder visible,
    // we need to add our layer to the map
    if (this.state.vehicle_layer !== null){
      this.state.map.removeLayer(this.state.vehicle_layer)
    }
    this.state.map.addLayer(clusteringLayer);
    this.setState({vehicle_layer:clusteringLayer})
  }

  drawEvents(){
    const H = window.H;
    let group = new H.map.Group();
    let color_list = ["(230, 25, 75, 0.5)", "(60, 180, 75, 0.5)", "(255, 225, 25, 0.5)", "(0, 130, 200, 0.5)", "(245, 130, 48, 0.5)", "(145, 30, 180, 0.5)", "(70, 240, 240, 0.5)", "(240, 50, 230, 0.5)", "(210, 245, 60, 0.5)", "(250, 190, 212, 0.5)", "(0, 128, 128, 0.5)", "(220, 190, 255, 0.5)", "(170, 110, 40, 0.5)", "(255, 250, 200, 0.5)", "(128, 0, 0, 0.5)", "(170, 255, 195, 0.5)", "(128, 128, 0, 0.5)", "(255, 215, 180, 0.5)", "(0, 0, 128, 0.5)", "(128, 128, 128, 0.5)", "(255, 255, 255, 0.5)", "(0, 0, 0, 0.5)"]
    this.state.map.removeObjects(this.state.map.getObjects())

    let svg = `<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 100 100">
              <circle cx="50" cy="50" r="50" fill="FILL_COLOR" opacity=".8"/>
              <circle cx="50" cy="50" r="8" fill="black"/>
              </svg>`;
    let size = {w: 10, h: 10};
    let icons = {};

    this.props.events.map(
        (evt) => {
            if (!(evt.pub_id in icons)){
              let cur_color = color_list.shift();
              color_list.push(cur_color);//add the color to the back to prevent running out of colors
              icons[evt.pub_id] = new H.map.Icon(
                svg.replace('FILL_COLOR', "rgba" + cur_color),
                { size,}
                );
            }
            const latlon = Geohash.decode(evt.geohash);
            group.addObject(new H.map.Marker({
                lat: latlon.lat,
                lng: latlon.lon,
              },{icon: icons[evt.pub_id]}));
        }
    )
    this.state.map.addObject(group);
  }

  drawRoute(){
    const H = window.H;
    let group = new H.map.Group();
    let color_list = ["(0, 0, 255, 0.8)", "(60, 180, 75, 0.5)", "(255, 225, 25, 0.5)", "(0, 130, 200, 0.5)", "(245, 130, 48, 0.5)", "(145, 30, 180, 0.5)", "(70, 240, 240, 0.5)", "(240, 50, 230, 0.5)", "(210, 245, 60, 0.5)", "(250, 190, 212, 0.5)", "(0, 128, 128, 0.5)", "(220, 190, 255, 0.5)", "(170, 110, 40, 0.5)", "(255, 250, 200, 0.5)", "(128, 0, 0, 0.5)", "(170, 255, 195, 0.5)", "(128, 128, 0, 0.5)", "(255, 215, 180, 0.5)", "(0, 0, 128, 0.5)", "(128, 128, 128, 0.5)", "(255, 255, 255, 0.5)", "(0, 0, 0, 0.5)"]
    this.state.map.removeObjects(this.state.map.getObjects())

    let svg = `<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 100 100">
              <circle cx="50" cy="50" r="50" fill="FILL_COLOR" opacity=".8"/>
              <circle cx="50" cy="50" r="8" fill="black"/>
              </svg>`;
    let size = {w: 10, h: 10};
    let icons = {};
    let cur_color = color_list.shift();
    color_list.push(cur_color);//add the color to the back to prevent running out of colors
    icons[0] = new H.map.Icon(
      svg.replace('FILL_COLOR', "rgba" + cur_color),
      { size,}
      );
    group.addObject(new H.map.Marker({
      lat: this.props.mypos[0],
      lng: this.props.mypos[1],
    },{icon: icons[0]}));
    cur_color = color_list.shift();
    color_list.push(cur_color);//add the color to the back to prevent running out of colors
    size = {w: 5, h: 5}
    icons[1] = new H.map.Icon(
      svg.replace('FILL_COLOR', "rgba" + cur_color),
      { size,}
      );
    this.props.route.map(
        (evt) => {
            group.addObject(new H.map.Marker({
                lat: evt[0],
                lng: evt[1],
              },{icon: icons[1]}));
        }
    )
    this.state.map.addObject(group);
  }
  componentDidUpdate(prevProps,prevState){
    if (prevProps.events !== this.props.events){
      this.props.events ? this.drawEvents():console.log("No Events");
    }
    //if (prevProps.vehicles == this.props.vehicles){
    this.props.vehicles ? this.startClustering():console.log("No Vehicles");
    this.props.route ? this.drawRoute():console.log("No Route");
    // get geo bounding box for the group and set it to the map
 /*   if (group.getObjects().length >= 1){
      let bbox = group.getBoundingBox();
      let c = new H.geo.Rect(bbox.getTop()*1.01, bbox.getLeft()*0.99, bbox.getBottom()*0.99, bbox.getRight()*1.01)
     this.state.map.getViewModel().setLookAtData({
        bounds: c,
     });
    }
*/
  }

  componentWillUnmount() {
    // Cleanup after the map to avoid memory leaks when this component exits the page
    this.state.map.dispose();
  }

  render() {
    console.log("Rendering map")
    return (
      // Set a height on the map so it will display
      <div ref={this.mapRef} style={{ height: Math.round(window.innerHeight*0.8) }} />
    )
  }
}

    /*let lat,lon = Geohash.decode(evt.geohash);
    console.log("evt")
    map.addObject(new H.map.Marker({
        lat: lat,
        lng: lon,
      }
      )
    )
    })*/