import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const SideMenuItem = ({
  itemName,
  link,
  iconname,
  collapse,
  activeFcn,
  isActive,
}) => {
  const [showPopOver, setShowPopOver] = useState(false);
  const [posX, setPosX] = useState(null);
  const [posY, setPosY] = useState(0);

  function onHoverMenu(event) {
    setShowPopOver(!showPopOver);
    const elem = event.target;
    setPosX(elem.getBoundingClientRect().left + elem.offsetWidth + 2);
    setPosY(elem.getBoundingClientRect().top);
  }
  return (
    <li class={`sdds-sidebar-nav__item ${isActive? 'sdds-item--active':''}`}>
      <NavLink
        className={`nav-link sdds-sidebar-nav__item`}
        to={link}
        style={{ color: 'black' }}
        onMouseEnter={(e) => onHoverMenu(e)}
        onMouseLeave={() => setShowPopOver(!showPopOver)}
        onClick={()=> {activeFcn(itemName)}}
      >
        <a className="sdds-sidebar-nav__item-link" href={link}>
        <span className="sdds-sidebar-nav__icon">
          <sdds-icon name={iconname} style={{ fontSize: "2em" }}></sdds-icon>
        </span>
        <span className="sdds-sidebar-nav__item-text">{itemName}</span>
        </a>
      </NavLink>
    </li>
  );
};

export default SideMenuItem;

/*<li class={`sdds-sidebar-nav__item ${isActive? 'sdds-item--active':''}`}>
      <NavLink
        className={`sdds-navbar-menu-item`}
        activeClassName={isActive ? "active" : ""}
        to={link}
        style={{ color: "black" }}
        //onMouseEnter={(e) => onHoverMenu(e)}
        //onMouseLeave={() => setShowPopOver(!showPopOver)}
        onClick={() => {
          activeFcn(itemName);
        }}
      >
        <span
          className="sdds-navbar-icon-button"
          style={{ width: "40px", height: "40px" }}
        >
          <sdds-icon name={iconname} style={{ fontSize: "3em" }}></sdds-icon>
        </span>
        <a className="sdds-navbar-menu-item-link" href={link}>
          {itemName}
        </a>
      </NavLink>
      {collapse && showPopOver && (
        <div
          className={"sdds-navbar-menu-popover"}
          style={{ left: `${posX}px`, top: `${posY}px`, fontWeight: "bold" }}
        >
          <div className="sidebar-menu-popover">
            <a className="sdds-navbar-menu-item-link" href={link}>
              {itemName}
            </a>
          </div>
        </div>
      )}
    </li>*/