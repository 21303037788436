import React  from 'react';
import {useSettings} from "../../contexts/settingsContext"
import SettingsParagraph from './SettingsParagraph.js'

const SettingsV2 = ()=>{
  const { currentSettings, setToDefaultSettings} = useSettings();
  return(
    <>
      <button class="sdds-btn sdds-btn-primary" onClick={() => setToDefaultSettings()} >Reset to default</button>
      <div class="sdds-container">
        {Object.keys(currentSettings).map((key, index) =>{
          return( <SettingsParagraph path={key} settings={currentSettings} /> )
          })
        }

      </div>
    </>
    )
}

export default SettingsV2
