import React, {  useEffect} from "react";

import { useWebSocketContext } from "../../contexts/websocketContext";
import { DisplayMapClass } from "../map/HereMap";
import { useSettings } from "../../contexts/settingsContext";
import { useUserContext} from "../../contexts/userContext";

export const Vehicles = () => {
    const { sendMessage, vehiclesMessage } = useWebSocketContext();
    const {currentSettings} = useSettings();
    const {getUserName} = useUserContext();

    const getData = () => {
        console.log("Fetch vehicle data");
        sendMessage(`{"action":"vehicles", "username":"${getUserName()}"}`)
    }

    useEffect(() => {
        if (typeof vehiclesMessage === "undefined" || vehiclesMessage === null){
            getData();
        }
        const timerId = setInterval(() => {
            getData()
        }, currentSettings.map.eventFetcher.value * 1000*10);
        return () => clearInterval(timerId);
      },[vehiclesMessage]);

    return (
        <>
        <div className="component-wrapper">
            {
            typeof vehiclesMessage === "undefined" || vehiclesMessage === null ?
            <div>No data available, waiting for server</div>:
            <>Currently {vehiclesMessage.length} vehicles online on monitored road
            <DisplayMapClass vehicles={vehiclesMessage} settings={currentSettings} /></>
            }
        </div>
        </>
    );
  };