import React, {  useEffect } from "react";

import { useWebSocketContext } from "../../contexts/websocketContext";
import { DisplayMapClass } from "../map/HereMap";
import { useSettings } from "../../contexts/settingsContext";
import { useUserContext} from "../../contexts/userContext";

export const CombinedBoard = () => {
    const { sendMessage, eventMessage, vehiclesMessage } = useWebSocketContext();
    const {currentSettings} = useSettings();
    const {getUserName} = useUserContext();

    const getData = () => {
        console.log("Fetch event data");
        sendMessage(`{"action":"eventfetch", "filter_text":"${currentSettings.map.eventFilter.value}","filter_parameter":"pub_id","fetch_external":"${currentSettings.map.fetchExternalData.value}"}`)
        sendMessage(`{"action":"vehicles", "username":"${getUserName()}"}`)
    }
    useEffect(() => {
        getData();
        const timerId = setInterval(() => {
            getData()
        }, currentSettings.map.eventFetcher.value * 5000);
        return () => clearInterval(timerId);
      }, []);


    return (
        <>
        <div>
            Currently:
            <ul>
                <li>{typeof eventMessage === "undefined" || eventMessage === null ? 0: eventMessage.length} events available and active.</li>
                <li>{typeof vehiclesMessage === "undefined" || vehiclesMessage === null ? 0: vehiclesMessage.length} vehicles available and active on monitored road </li>
            </ul>
        </div>
        <div className="component-wrapper">
            <DisplayMapClass events={eventMessage} vehicles={vehiclesMessage} settings={currentSettings}/>
        </div>

        </>
    );
  };