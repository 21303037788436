import { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
import Header from "./components/header/Header";
import Form from "./components/form/Form";
import Home from "./components/home/Home";
import Footer from "./components/footer/Footer";
import SideMenu from "./components/sideMenu/SideMenu";
import SettingsV2 from "./components/settings/SettingsV2";
import Dashboard from "./components/dashboard/Dashboard";
import Location from "./components/location/Location";
import Information from "./components/information/Information";
import Map from "./components/map/Map";
import User from "./components/user/User";
import { useSettings } from "./contexts/settingsContext";
import { usePosition } from "./contexts/positionContext";
import { useResizeContext } from "./contexts/resizeContext";
import { WebSocketDemo } from "./components/websockets/webSocket";
import { Eventboard } from "./components/eventboard/Eventboard";
import {Graphs} from "./components/graphs/Graphs";
import {Vehicles} from "./components/vehicles/Vehicles";
import { CombinedBoard } from "./components/combinedboard/CombinedBoard";

function App() {
  const { currentSettings  } = useSettings();
  const { onMobile} = useResizeContext()
  const { position } = usePosition();
  const [expand, setExpand] = useState(false);
  return (
    <div id="root" className="App">
      <Router>
        <sdds-theme />
        <div className={`sdds-navbar-overlay ${expand ? 'expanded' : ''}`} />
        <Header onDrawerClick={() => setExpand(!expand)} expand={expand} />
        <div className="sdds-push sdds-demo-container">
          <SideMenu expand={expand} onCloseClick={() => setExpand(!expand)}/>
          <div className={"sdds-content-push"}>
            <div className="sdds-container-fluid content-wrapper">
              <Switch>
                <Route exact path="/">
                  <Information />
                </Route>
                <Route path="/form">
                  <Form />
                </Route>
                <Route path="/settings">
                  <SettingsV2 />
                </Route>
                <Route path="/dashboard">
                  <Dashboard />
                </Route>
                <Route path="/location">
                  <Location />
                </Route>
                <Route path="/map">
                  <Map position={position} settings={currentSettings} />
                </Route>
                <Route path="/info">
                  <Home position={position} />
                </Route>
                <Route path="/user">
                  <User />
                </Route>
                <Route path="/ws">
                  <WebSocketDemo />
                </Route>
                <Route path="/eb">
                  <Eventboard />
                </Route>
                <Route path="/graphs">
                  <Graphs />
                </Route>
                <Route path="/vehicles">
                  <Vehicles />
                </Route>
                <Route path="/combined">
                  <CombinedBoard />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
        {onMobile ? <p> Copyright &copy; 2023 Scania, build time {process.env.REACT_APP_BUILD_TIME}</p> : <Footer /> }
      </Router>
    </div>
  );
}

export default App;
