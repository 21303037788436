import React, { useRef, useEffect,useState } from "react";
import {useWebSocketContext}  from "../../contexts/websocketContext";
import {usePosition} from "../../contexts/positionContext";
import {useSettings} from "../../contexts/settingsContext";
import {useResizeContext} from "../../contexts/resizeContext"

const Dashboard = (props) => {
  const canvas = useRef();
  const layout = {
    warning:  [0.1,0.1,0.8,0.1],
    warningPict: [0.11,0.11,0.08,0.08],
    warningText: [0.21,0.11,0.68,0.08],
    roadinfo: [ 0.1,0.2,0.8,0.1 ],
    roadSign: [ 0.2,0.4,0.2,0.1 ],
    laneSign: [0.1,0.6,0.15,0.1],
    road:     [ 0.05,0.3,0.9,0.6 ],
    vehicle:  {speed:[ 0.4,0.9,0.2,0.1 ],
               position: [0.7,0.9,0.2,0.1]
              },
    warningSimulation: {
              loop: [0,0,0.1,0.1],
              eva: [0.1,0,0.1,0.1],
              rww: [0.2,0,0.1,0.1],
              slip: [0.3,0,0.1,0.1],
              acc: [0.4,0,0.1,0.1],
              tj:  [0.5,0,0.1,0.1],
              bridge: [0.6,0,0.1,0.1],
              none: [0.9,0,0.1,0.1],
    },
    mcsSimulation: {
      none: [0.9,0.1,0.1,0.1],
      speedLimit: [0.9,0.2,0.1,0.1],
      laneClosedRight: [0.9,0.3,0.1,0.1],
      laneClosingRight: [0.9,0.4,0.1,0.1],
      laneClosingLeft: [0.9,0.5,0.1,0.1],
      laneClosedLeft: [0.9,0.6,0.1,0.1],
}
  }
  const [ctx2,setCtx2] = useState(null);
  const { snapMessage,warningMessage, mcsMessage} = useWebSocketContext();
  const [localWarningMessage, setLocalWarningMessage] = useState(null)
  const [localMCSMessage, setLocalMCSMessage] = useState(null)
  const { position,resetSim,simIndex} = usePosition();
  const {currentSettings} =useSettings();
  const {width, height,showSide,collapseSide} = useResizeContext();
  const [x_max,setXmax] = useState(width*0.5)
  const [y_max,setYmax] = useState(height*0.5)
  const [roadSpeedLimit,setRoadSpeedLimit] = useState(currentSettings.dashboard.maxSpeedVehicle.value);
  const [roadRef, setRoadRef] = useState("test vägnr");

  useEffect(()=>{
    setLocalMCSMessage(mcsMessage)
  },[mcsMessage,setLocalMCSMessage])

  useEffect(()=>{
    switch (true){
      case (showSide === true && collapseSide === false):
        setXmax(Math.round(0.7*width))
        break
      case (showSide === true && collapseSide === true):
        setXmax(Math.round(0.9*width))
        break
      case (showSide === false) :
        setXmax(Math.round(0.8*width))
        break
      default:
        setXmax(Math.round(width*0.5))
    }
    setYmax( Math.min(Math.round(height*0.7),x_max))
    console.log("new size canvas",x_max,y_max)
    if (ctx2){ctx2.clearRect(0,0,x_max,y_max)}
  },[collapseSide,showSide,width,height,setXmax,setYmax,ctx2,x_max,y_max])

  // Function to get the mouse position
  const getMousePos = (canvas, event) => {
  var rect = canvas.getBoundingClientRect();
  return {
    x: event.clientX - rect.left,
    y: event.clientY - rect.top,
  };
  }
  // Function to check whether a point is inside a rectangle
  const isInside = (pos, rect) => {
    return pos.x > rect.x && pos.x < rect.x + rect.width && pos.y < rect.y + rect.height && pos.y > rect.y
  }

  const arrayToRect = (pointList) =>{
    return {
      x: pointList[0],
      y: pointList[1],
      width: pointList[2],
      height: pointList[3]
    }
  }

  useEffect(()=>{
    console.log("updating context")
    canvas.current.width = x_max;
    canvas.current.height = y_max;
    setCtx2(canvas.current.getContext("2d"));

    canvas.current.addEventListener('click', function(evt) {
      var mousePos = getMousePos(canvas.current, evt);
      var rect =  {
        x:layout.vehicle.position[0]*x_max,
        y:layout.vehicle.position[1]*y_max,
        width:layout.vehicle.position[2]*x_max,
        height:layout.vehicle.position[3]*y_max
      }
      if (isInside(mousePos, rect)) {
        if (position.source === "SIM"){
          console.log("Resetting simulator")
          resetSim();
        }

      }
      for (const [key, value] of Object.entries(layout.warningSimulation)) {
        let testBox = arrayToRect(convertLayoutToRightSize(value));
        if (isInside(mousePos, testBox)){
          console.log(`Clicked in ${key}`)
          switch (key) {
            case "eva":
                setLocalWarningMessage({type: "eva"})
              break;
            case "rww":
                setLocalWarningMessage({type: "rww"})
              break;
            case "tj":
                setLocalWarningMessage({type: "tj"})
              break;
            case "bridge":
                setLocalWarningMessage({type: "bridge"})
              break;
            case "acc":
                setLocalWarningMessage({type: "acc"})
              break;
            case "slip":
                setLocalWarningMessage({type: "slip"})
              break;
            case "none":
                setLocalWarningMessage(null)
              break;
            default:
              break;
          }
        }
      }
      for (const [key, value] of Object.entries(layout.mcsSimulation)) {
        let testBox = arrayToRect(convertLayoutToRightSize(value));
        if (isInside(mousePos, testBox)){
          console.log(`Clicked in ${key}`)
          switch (key) {
            case "speedLimit":
                setLocalMCSMessage([{speed:50},{speed:70},{speed:70},{speed:70},{speed:70},{speed:70}])
              break;
              case "laneClosedRight":
                setLocalMCSMessage([{cross:true},{speed:30},{speed:50},{speed:70},{speed:70},{speed:70}])
              break;
              case "laneClosingRight":
                setLocalMCSMessage([{arrow:"left"},{speed:50},{speed:50},{speed:70},{speed:70},{speed:70}])
              break;
             case "laneClosedLeft":
                setLocalMCSMessage([{speed:30},{cross:true},{cross:true},{cross:true},{cross:true},{cross:true}])
              break;
              case "laneClosingLeft":
                setLocalMCSMessage([{speed:30},{arrow:"right"},{cross:true},{cross:true},{cross:true},{cross:true}])
              break;
            case "none":
            //default:
              setLocalMCSMessage(null)
              break;
            default:
              break;
          }
        }
      }
    }, false);

  },[x_max,y_max])

  const printText= (textStr,locationRectPerc, debug, style={fontSize:20,fontColor:'black'}) =>{
    const locationRect = convertLayoutToRightSize(locationRectPerc);
    if (ctx2){
      ctx2.clearRect(...locationRect)
      if (debug){
        ctx2.strokeStyle = "purple";
        ctx2.lineWidth = 1;
        ctx2.beginPath();
        ctx2.rect(...locationRect);
        ctx2.stroke();
      }
      ctx2.font = `${style.fontSize}px Courier`;
      ctx2.fillStyle = style.fontColor;
      ctx2.textAlign = "center";
      ctx2.fillText(textStr, (locationRect[0]+locationRect[2]/2),(locationRect[1]+locationRect[3]*2/3))
    }
  }

  const convertLayoutToRightSize = (layout_data) =>{
    return [layout_data[0]*x_max,layout_data[1]*y_max, layout_data[2]*x_max,layout_data[3]*y_max]
  }

  //update road text
  useEffect(()=>{
    if (roadRef !== null) {
      printText(roadRef,layout.roadinfo,currentSettings.dashboard.drawInfoDisplayDebug.value,
      {fontSize: Math.round(x_max*0.03),fontColor: 'black'})
    }
  },[ctx2,roadRef,x_max,layout.road])

  //Plot vehicle data (speed, position)
  useEffect(()=>{
    let speed_display = position.speed<0 ? "-" : (position.speed*3.6).toFixed(0).toString() + " km/h"
    printText(speed_display,layout.vehicle.speed,currentSettings.dashboard.drawInfoDisplayDebug.value,
      {fontSize: Math.round(x_max*0.03),
      fontColor: position.speed*3.6 <= roadSpeedLimit ? 'black':'red'})
    let positionText = position.geohash + " (" + simIndex + ")";
    printText(positionText,layout.vehicle.position,currentSettings.dashboard.showDevParameters.value,
      {fontSize: Math.round(x_max*0.015),
       fontColor: 'black'
      })
  },[ctx2,position,x_max,simIndex, layout.vehicle.position, layout.vehicle.speed,currentSettings.dashboard.drawInfoDisplayDebug.value,printText])

  //plot the road lanes
  useEffect(() => {
    if (ctx2){
      const displayRect = convertLayoutToRightSize(layout.road)
      ctx2.clearRect(...displayRect)
        if (currentSettings.dashboard.drawInfoDisplayDebug.value){
          ctx2.strokeStyle = "green";
          ctx2.lineWidth = 1;
          ctx2.beginPath();
          ctx2.rect(...displayRect);
          ctx2.stroke();
        }
    const horizon = [0.5*x_max,0.08*y_max]
    //the main road
    const roadWidth = 0.8*x_max;
    const roadCenter = 0.5*x_max;
    drawLane(0,roadCenter,roadWidth ,horizon,10,0)

    const lanes = snapMessage.hasOwnProperty("lanes") ? snapMessage.lanes : 2
    const one_way = (snapMessage.hasOwnProperty("one_way") && snapMessage.one_way==="1") ? true :false
    //console.log("DASH: Lanes=",lanes, ", oneway=",one_way)
    const lane_width = roadWidth / lanes;
    for (let index = 0; index < lanes; index++) {
      //console.log("DASH: plotting lane ",index+1)
      let directionIndicator = one_way && index>0 ? 1 : index===0 ? 1 : -1
      if (index>1){
        //console.log("Multiple lanes")
      }
      drawLane(index,roadCenter+roadWidth/2-(lane_width/2)-lane_width*index, lane_width,horizon,3,directionIndicator)
      //if we have several lanes, there can be info box in the middle of two lanes, but not if one-way
      if (lanes>1 && index > 0 && index<lanes && one_way === false){
        drawLaneInfoBox(roadCenter+(roadWidth/2-lane_width*index)*0.6,convertLayoutToRightSize(layout.laneSign)[1],false)
      }
    }

    //road info sign
    if (localMCSMessage != null && checkMCSSpeedLimit()==true){
      const displayRect = convertLayoutToRightSize(layout.roadSign)
      ctx2.clearRect(...displayRect)
      //if there is mcs data, we might not want to show road limit
      drawLaneInfoBox(roadCenter,convertLayoutToRightSize(layout.roadSign)[1],false)

    } else {
      drawLaneInfoBox(roadCenter,convertLayoutToRightSize(layout.roadSign)[1],true,roadSpeedLimit)
    }


    if (snapMessage.hasOwnProperty("road_ref")){
      if (currentSettings.dashboard.showDevParameters.value === true){
        ctx2.font = `${Math.round(x_max*0.015)}px Courier`;;
        ctx2.fillText("next " +snapMessage.distance_out.toFixed(0) + " m", x_max*(layout.road[0]+layout.road[2]*8/10), y_max*(layout.road[1]+layout.road[3]*0.1));
        ctx2.fillText("seg id " + snapMessage.id, x_max*(layout.road[0]+layout.road[2]*8/10), y_max*(layout.road[1]+layout.road[3]*0.2));
        ctx2.fillText("in "+snapMessage.distance_in.toFixed(0) + " m", x_max*(layout.road[0]+layout.road[2]*8/10), y_max*(layout.road[1]+layout.road[3]*0.3));
      }
    }


  }
  }, [localMCSMessage,snapMessage,currentSettings,y_max,x_max,ctx2,layout.road,currentSettings.dashboard.drawInfoDisplayDebug.value, layout.position, printText,roadSpeedLimit]);

  const checkMCSSpeedLimit = () => {
    //check if first mcs data contains active speed limit
    let limit = false;
    for (let index = 0; index < localMCSMessage[0].vms.length; index++) {
      if (localMCSMessage[0].vms[index].dynamic.speed != null){
          limit = true;
          break;
      }
    }
    return limit;
  }

  const findLaneSpeedLimit = (laneIndex) => {
    let speedLimit = 200;
    let tmpLimit = 0;
    if (localMCSMessage){
      for (let index = 0; index < localMCSMessage[0].lanes[laneIndex].length; index++) {
        tmpLimit = localMCSMessage[0].vms[localMCSMessage[0].lanes[laneIndex][index]].dynamic.speed;
        if ( tmpLimit != null){
          speedLimit = Math.min(speedLimit,tmpLimit);
        }
      }
    }
    return speedLimit<200 ? speedLimit : null
  }


  useEffect(()=>{
    //when new warning arrives, update the local copy that is used to enable triggering directly from gui
    setLocalWarningMessage(warningMessage)
  },[warningMessage,setLocalWarningMessage])

  useEffect(()=>{
    //remove the warning after given period of time
    const timer = setTimeout(() => {
      console.log('Reset warning');
      setLocalWarningMessage(null)}, currentSettings.dashboard.warningDisplayTimeOut.value*1000);
    return () => clearTimeout(timer);
  },[localWarningMessage,setLocalWarningMessage,currentSettings.dashboard.warningDisplayTimeOut.value])


  useEffect(()=>{
    //remove the mcs after given period of time
    const timer = setTimeout(() => {
      console.log('Reset mcs');
      setLocalMCSMessage(null)}, currentSettings.dashboard.warningDisplayTimeOut.value*1000);
    return () => clearTimeout(timer);
  },[localMCSMessage,setLocalMCSMessage,currentSettings.dashboard.warningDisplayTimeOut.value])

  useEffect(()=>{
    //make it possible to trigger warnings in gui, so react on local copy
    if (ctx2 && localWarningMessage !== null) {
      displayWarning()
    }
    else if(ctx2){
      ctx2.clearRect(...convertLayoutToRightSize(layout.warning))
    }
  },[localWarningMessage,ctx2])

  useEffect(()=>{
    if(snapMessage.hasOwnProperty("max_speed")){
      setRoadSpeedLimit(Math.min(snapMessage.max_speed,currentSettings.dashboard.maxSpeedVehicle.value));
    }
    if (snapMessage.hasOwnProperty("road_ref")){
      setRoadRef(snapMessage.road_ref)
    }
  },[snapMessage])



  const drawLaneInfoBox = (x,y,legal_ring=false,speed_limit=null,cross=false,arrow=null,box=false) => {
    const width = x_max * 0.08;
    const height = width * 0.8
    const displayRect = [x-width/2, y, width, height];
    ctx2.clearRect(...displayRect)
    if (currentSettings.dashboard.drawInfoDisplayDebug.value || box){
      ctx2.strokeStyle = box ? "black" : "red";
      ctx2.lineWidth = 1;
      ctx2.beginPath();
      ctx2.rect(...displayRect);
      ctx2.stroke();
    }
    if (legal_ring){
      drawCircle({x:x, y:y+height/2, r:width/2*0.9},{color:"red",width:x_max*0.01})
    }
    if (cross){
      drawX(displayRect[0]+displayRect[2]/2,displayRect[1]+displayRect[3]/2,displayRect[2]/2,displayRect[3]/2,ctx2)
    }
    if (arrow){
      drawArrow(displayRect[0]+displayRect[2]/2,displayRect[1]+displayRect[3]/2,displayRect[2]/2,displayRect[3]/2,ctx2,arrow)
    }
    if (speed_limit !== null){
      ctx2.textAlign = "center";
      ctx2.font = `${Math.round(width*0.55)}px Courier`;
      ctx2.fillText( speed_limit, x,y+height*2/3)
    }
  }

  function drawX(x, y,x_step,y_step,ctx) {
    ctx.beginPath();
    ctx.strokeStyle = "red";
    ctx.lineWidth = x_max*0.01
    ctx.moveTo(x - x_step, y - y_step);
    ctx.lineTo(x + x_step, y + y_step);

    ctx.moveTo(x + x_step, y - y_step);
    ctx.lineTo(x - x_step, y + y_step);
    ctx.stroke();
}

function drawArrow(x, y,x_step,y_step,ctx,direction) {
  let factor = 1
  if (direction==="left"){
    factor = -1
  }

  ctx.beginPath();
  ctx.strokeStyle = "orange";
  ctx.lineWidth = x_max*0.01
  ctx.moveTo(x - x_step*factor, y - y_step);
  ctx.lineTo(x + x_step*factor, y + y_step);

  ctx.moveTo(x + x_step*factor, y);
  ctx.lineTo(x + x_step*factor, y + y_step);
  ctx.lineTo(x , y + y_step);
  ctx.stroke();
}

  const displayWarning=()=>{
    const displayRect = convertLayoutToRightSize(layout.warning)
    ctx2.clearRect(...displayRect)
    if (currentSettings.dashboard.drawInfoDisplayDebug.value){
      ctx2.strokeStyle = "red";
      ctx2.lineWidth = 1;
      ctx2.beginPath();
      ctx2.rect(...displayRect);
      ctx2.stroke();
    }
    const img = new Image();
    ctx2.globalCompositeOperation = 'source-over';
    ctx2.fillStyle = "black"
    ctx2.fillRect(...convertLayoutToRightSize(layout.warningPict))
    ctx2.fillStyle = "#edeff3"
    ctx2.fillRect(...convertLayoutToRightSize(layout.warningPict))
    ctx2.clearRect(...convertLayoutToRightSize(layout.warningPict))

    img.onload = function () {ctx2.drawImage(img, ...convertLayoutToRightSize(layout.warningPict));
        // set composite mode
        //ctx2.globalCompositeOperation = "source-in";
        //ctx2.globalCompositeOperation = 'source-over';
        // draw color
        //ctx.fillStyle = "#09f";
        //ctx.fillRect(0, 0, 100, 100);
      }
    var dispText = ""
    if (localWarningMessage !==null) {
      switch (localWarningMessage.type){
        case "rww":
          img.src = './pictures/rww_solid.png';
          dispText = "Road works ahead";
          break;
        case "eva":
          img.src = './pictures/eva_solid.png';
          dispText = "Emergency vehicle approaching";
          break;
        case "acc":
          img.src = './pictures/accident_solid.png';
          dispText = "Accident ahead";
          break;
        case "tj":
          img.src = './pictures/trafficjam_solid.png';
          dispText = "Traffic jam ahead";
          break;
        case "slip":
          img.src = './pictures/slippery_solid.png';
          dispText = "Slippery road conditions ahead";
          break;
        case "bridge":
          img.src = './pictures/bridge.svg';
          dispText = "Bridge opening ahead";
          break;
        default:
          //img.src = "./pictures/slippery.png";
      }
    }

    ctx2.fillStyle = "black"
    ctx2.font = `${Math.round(width*0.05)}px Courier`;
    printText(dispText,layout.warningText,currentSettings.dashboard.drawInfoDisplayDebug.value,{
      fontSize: Math.round(x_max*0.03)
    })
  }

  const drawLane = (index,x,w,horizon,width=5,indicator=1)=>{
    let legal_ring;
    let lane_speed;
    let cross;
    let arrow;
    let box;
    //console.log("DASH: draw lane:",x,"+",w)
    const x_l = x - w/2;
    const x_r = x + w/2;
    const y_bottom = y_max*0.9;
    const y_top = y_max * 0.4;
    if (currentSettings.dashboard.drawLaneDebug.value) {
      const style = {color:"red",width:1};
      drawLine({x:x,y:y_bottom,x1:horizon[0],y1:horizon[1]},style)
      drawLine({x:x_l,y:y_bottom,x1:horizon[0],y1:horizon[1]},style)
      drawLine({x:x_r,y:y_bottom,x1:horizon[0],y1:horizon[1]},style)
    }
    const style2 = {color:"black",width:width};
    const length = y_bottom - horizon[1];
    const dx_t_l = horizon[0]-x_l;
    const dx_t_r = horizon[0]-x_r;
    const dy = y_bottom - y_top
    const dx_l = dx_t_l*dy/length
    const dx_r = dx_t_r*dy/length
    drawLine({x:x_l,y:y_bottom,x1:x_l+dx_l,y1:y_top},style2)
    drawLine({x:x_r,y:y_bottom,x1:x_r+dx_r,y1:y_top},style2)
    if (indicator===1) drawRoadInd({x: x-w/6, y:y_bottom-y_max*0.03,x1:x+w/6,y1:y_bottom-y_max*0.03, z:-y_max*0.03},{ color: "black", width: 5 })
    if (indicator===-1) drawRoadInd({x: x-w/6, y:y_bottom-y_max*0.03,x1:x+w/6,y1:y_bottom-y_max*0.03, z:y_max*0.03},{ color: "black", width: 5 })
    if (indicator===1){ //only our direction of travel
      if (localMCSMessage){
        if ("distance" in localMCSMessage[0]){
          //message from backend, only take first portal
          //it can be that snap has come back with more lanes than we have in the mcs, so then ignore the mcs
          //this doesn't work, because on the E18 snap says 3 lanes, but 1 is buslane, and trafikverket only returns 2 lanes
          //we would like to see the data in this case any how..
          if (true) {
            //(Object.keys(localMCSMessage[0].lanes).length == snapMessage.lanes){

            let use_index = index+1;
            if (currentSettings.dashboard.laneCountingFromLeft.value==true) {
              //instead of lane 1 being most right, we start from the most left lane
              // amount of lanes (4) - index (0) -1 -> 3
              use_index = Object.keys(localMCSMessage[0].lanes).length - index -1;
            }
            if (use_index > Object.keys(localMCSMessage[0].lanes).length){
              //lane not available as mcs data
              legal_ring = false
              lane_speed = null
              cross = false
              arrow = null
              box = false
            }
            else{
              let temp = findLaneSpeedLimit(use_index)
              if (temp != null){
                let b = 1
              }
              let sign_data = localMCSMessage[0].vms[localMCSMessage[0].lanes[use_index][0]];
              legal_ring = sign_data["dynamic"]["legal_binding"] === 'true' ? true : false;
              lane_speed = findLaneSpeedLimit(use_index);
              cross = false
              arrow = null
              box = true
            }
          }
          else{
            console.log("Warning: mismatch on lanes in snap and mcs")
            legal_ring = false
            lane_speed = null
            cross = false
            arrow = null
            box = false
          }

        }else{
          //just set by clicking in hmi, not official
          legal_ring = "speed" in localMCSMessage[index] ? true : false
          lane_speed = "speed" in localMCSMessage[index] ? localMCSMessage[index].speed : null;
          cross = "cross" in localMCSMessage[index] ? true: false
          arrow = "arrow" in localMCSMessage[index] ? localMCSMessage[index].arrow: null;
          box = true
        }
      } else{
        legal_ring = false
        lane_speed = null
        cross = false
        arrow = null
        box = false
      }
      drawLaneInfoBox(x_l+(dx_l+w/2)*dy/length,convertLayoutToRightSize(layout.laneSign)[1],legal_ring,lane_speed,cross,arrow,box);
    }
  }

  const drawLine = (info, style = {}) => {
    //console.log("DASH: Draw line: ",info)
    const { x, y, x1, y1 } = info;
    const { color = "black", width = 1 } = style;
    ctx2.beginPath();
    ctx2.moveTo(x, y);
    ctx2.lineTo(x1, y1);
    ctx2.strokeStyle = color;
    ctx2.lineWidth = width;
    ctx2.stroke();
  };

  const drawRoadInd = (info, style={}) =>{
    const { x, y, x1, y1, z} = info;
    drawLine({x: x, y:y,x1:x+(x1-x)/2,y1:y+z}, style)
    drawLine({x: x1, y:y1,x1:x+(x1-x)/2,y1:y+z},style)
  }

  const drawCircle = (circle, style={})=>{
    const { x, y, r } = circle;
    const { color = "black", width = 1 } = style;
    ctx2.beginPath();
    ctx2.arc(x, y, r, 0, 2 * Math.PI);
    ctx2.strokeStyle = color;
    ctx2.lineWidth = width;
    ctx2.stroke();
    ctx2.closePath();
  }
  return (
    <>
      <canvas ref={canvas}></canvas>
    </>
  );
};
export default Dashboard;

/*
 {"id": 160403696,
  "distance_in": 131.02446360100305,
  "distance_out": 8.529591763802046,
  "snap_road_coord": {"lat": 59.18582766731454, "lon": 17.643155978572118},
  "road_ref": "E 4;E 20",
   "max_speed": 100,
    "lanes": 2,
    "one_way": true}}
  */