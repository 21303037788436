import React, { useCallback} from "react";

import { usePosition } from "../../contexts/positionContext";
import { useWebSocketContext } from "../../contexts/websocketContext";

export const WebSocketDemo = () => {
  const { position } = usePosition();
  const { sendMessage, connectionStatus, messageHistory } = useWebSocketContext();


  const handleClickSendMessage = useCallback(
    () => sendMessage('{"action":"sendmessage", "message":"hello world"}'),
    [sendMessage]
  );

  const handlePositionClickSendMessage = useCallback(
    () =>
      sendMessage(
        `{"action":"position", "message":${JSON.stringify(position)}}`
      ),
    [sendMessage, position]
  );


  return (
    <div>
      <button
        onClick={handleClickSendMessage}
        disabled={connectionStatus !== "Open"}
      >
        Send Hello
      </button>
      <button
        onClick={handlePositionClickSendMessage}
        disabled={connectionStatus !== "Open"}
      >
        send current position
      </button>{" "}
      <span>The WebSocket is currently {connectionStatus}</span>
      <div className="component-wrapper">
        <table className="sdds-table sdds-table--compact">
          <thead className="sdds-table__header">
            <tr className="sdds-table__row">
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Index (latest first)</th>
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Message data</th>
            </tr>
          </thead>
          <tbody className="sdds-table__body">
            {messageHistory ? (
              messageHistory.map((message, idx) => (
                <tr className="sdds-table__row" key={idx}>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{idx}</td>
                  <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{message ? message.data : " "}</td>
                </tr>
              ))) : (<tr></tr>)}
          </tbody>
        </table>
      </div>
    </div>
  );
};
