import "./Header.css";
import { NavLink } from "react-router-dom";
import { useResizeContext } from "../../contexts/resizeContext";
import { useWebSocketContext } from "../../contexts/websocketContext";

const Header = ({ onDrawerClick, expand }) => {
  const { showSide, setShowSide } = useResizeContext();
  const { connectionStatus } = useWebSocketContext();
  return (
    <nav class="sdds-nav sdds-nav__sidemenu sdds-u-fixed sdds-u-top0">
      <div class="sdds-nav__left">
        <div className="sdds-nav__overlay" />
        <button
          className={`sdds-nav__mob-menu-btn ${expand ? 'expanded' : ''}`}
          onClick={onDrawerClick}
        >
          <div id="sdds-nav__mob-menu-icon">
            <span
              className="sdds-nav__mob-menu-icon-line"
              id="sdds-nav__mob-menu-icon-line-1"
            />
            <span
              className="sdds-nav__mob-menu-icon-line"
              id="sdds-nav__mob-menu-icon-line-2"
            />
            <span
              className="sdds-nav__mob-menu-icon-line"
              id="sdds-nav__mob-menu-icon-line-3"
            />
          </div>
        </button>
        <div className="sdds-nav__app-name">Nordic Way 3</div>
      </div>
      <div class='sdds-nav__center'>
      <ul class='sdds-nav__inline-menu'>
      </ul>
      <ul class='sdds-nav__toolbar-menu'>
      <li class='sdds-nav__item sdds-nav__avatar'>
            <button class='sdds-nav__avatar-btn'>
            <NavLink
                  className={`sdds-navbar-menu-item`}
                  to={"/ws"}
                  style={{ color: "white" }}
              >
                  <span className="sdds-sidebar-nav__icon" >
                    <sdds-icon
                      name="scania-wifi"
                      style={{ fontSize: "2em" ,color:connectionStatus==="Open"?'green':'red'}}
                    ></sdds-icon>
                  </span>
                </NavLink>
                </button>
            </li>
            <li class='sdds-nav__item sdds-nav__avatar'>
                <button class='sdds-nav__avatar-btn'>
                    <NavLink
                  className={`sdds-navbar-menu-item`}
                  to={"/user"}
                  style={{ color: "white" }}
                >
                  <span className="sdds-sidebar-nav__icon" >
                    <sdds-icon
                      name="scania-profile_icon"
                      style={{ fontSize: "2em" }}
                    ></sdds-icon>
                  </span>
                </NavLink>
                </button>
            </li>
        </ul>
      </div>
        <div className="sdds-nav__right">
        <a className="sdds-nav__item sdds-nav__app-logo" href="#" />
      </div>
    </nav>

  );
};

export default Header;

/*     <header className="sdds-header">
      <nav className="sdds-navbar">
        <button
          className={`sdds-navbar-icon-button sdds-navbar-side-menu-drawer ${
            showSide ? "expanded" : ""
          }`}
          onClick={() => {
            console.log("click");
            setShowSide(!showSide);
          }}
        >
          <span className="sdds-icon-drawer" />
        </button>
        <div className="sdds-navbar-application-brand">Nordic Way 3</div>
        <div id="side-menu" className="sdds-navbar-collapsible">
          <div className="sdds-navbar-menu-toolbar-mobile">
            <ul className="sdds-navbar-menu-list">
              <li className="sdds-navbar-menu-item">
                <NavLink
                  className={`sdds-navbar-menu-item`}
                  to={"/user"}
                  style={{ color: "black" }}
                >
                  <span
                    className="sdds-navbar-icon-button"
                    style={{ width: "40px", height: "40px" }}
                  >
                    <sdds-icon
                      name="scania-profile_icon"
                      style={{ fontSize: "3em" }}
                    ></sdds-icon>
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>*/