import React, {  useEffect, useState} from "react";

import { useWebSocketContext } from "../../contexts/websocketContext";
import { useSettings } from "../../contexts/settingsContext";

export const Graphs = () => {
    const {sendMessage,graphsMessage} = useWebSocketContext();
    const [selectedGraph, setSelectedGraph] = useState(0);
    const [selectedPubId, setSelectedPubId] = useState(0);
    const [selectedTime, setSelectedTime] = useState('-PT6H');
    const timePeriod=["-PT3H","-PT6H","-PT12H","-P3D","-P7D"];
    const periods = {
        "-PT3H": 300,
        "-PT6H": 300,
        "-PT12H":600,
        "-P3D": 3600,
        "-P7D": 3600*8
    }
    const pubids = ['SE90001','SE90003','SE90006','SE90009','SE90010','SE00010','NL00002'];
    const graphs =[
        {
            name: "Interchange",
            width: Math.round(window.innerWidth*0.8),
            height: Math.round(window.innerHeight*0.7),
            view: "timeSeries",
            stacked: false,
            stat: "Sum",
            period: 300,
            title: "Messages",
            yAxis: {
                left: {
                    min: 0
                }
            },
            start: "-PT6H",
            end: "P0D",
            metrics: [
                [ "NordicWay3", "Messages", "OriginatingCountry", "SE", "MessageType", "CAM" ],
                [ "...", "DENM" ],
                [ "...", "MAPEM" ],
                [ "...", "SPATEM" ],
                [ "...", "SREM" ],
                [ "...", "DATEX2" ],

            ],
        },
        {
            name: "Interchange publisher",
            width: Math.round(window.innerWidth*0.8),
            height: Math.round(window.innerHeight*0.7),
            view: "timeSeries",
            stacked: false,
            stat: "Sum",
            period: 300,
            title: "Messages",
            yAxis: {
                left: {
                    min: 0
                }
            },
            start: "-PT6H",
            end: "P0D",
            metrics: [
                [ "NordicWay3", "Messages", "ICX", "SE00010"],
                [ "...", "SE90001" ],
                [ "...", "SE90003" ],
                [ "...", "SE90006" ],
                [ "...", "SE90009" ],
                [ "...", "SE90010" ],
                [ "...", "NO00000" ],
                [ "...", "NO00005" ],
                [ "...", "NL00002" ],
            ],
        },
        {
            name: "Scania Position Messages",
            width: Math.round(window.innerWidth*0.8),
            height: Math.round(window.innerHeight*0.7),
            view: "timeSeries",
            stacked: false,
            stat: "Sum",
            period: 300,
            title: "Position messages",
            yAxis: {
                left: {
                    min: 0
                }
            },
            start: "-PT6H",
            end: "P0D",
            metrics: [
                [ "NordicWay3", "Positions", "Type", "Global" ],
                [ "...", "InZoneOfInterest" ],
                [ "...", "MatchedToRoad" ]
            ],
        },
        {
            name: "Scania Vehicles",
            width: Math.round(window.innerWidth*0.8),
            height: Math.round(window.innerHeight*0.7),
            view: "timeSeries",
            stacked: false,
            stat: "Average",
            period: 300,
            title: "Vehicles online",
            yAxis: {
                left: {
                    min: 0
                }
            },
            start: "-PT6H",
            end: "P0D",
            metrics: [
                [ "NordicWay3", "Vehicles", "KPI", "nw3-infra-fmp-processor-vehicle-pos-table4" ],
                [ "...", "nw3-infra-fmp-processor-vehicle-raw-table4" ]
            ],
        },
        {
            name: "ECS cluster",
            metrics: [
                [ "AWS/ECS", "CPUUtilization", "ClusterName", "nw3-interchange-ecs", "ServiceName", "nw3-interchange-fargate-service", { "stat": "Average", "visible": false } ],
                [ ".", "MemoryUtilization", "ServiceName", "nw3-interchange-fargate-service", "ClusterName", "nw3-interchange-ecs", { "stat": "Average" } ],
                [ ".", "CPUUtilization", ".", ".", ".", ".", { "stat": "Average" } ]
            ],
            legend: {
                "position": "bottom"
            },
            liveData: true,
            title: "Ecs cluster",
            view: "timeSeries",
            stacked: false,
            period: 300,
            yAxis: {
                left: {
                    min: 0,
                    max: 100
                }
            },
            width: Math.round(window.innerWidth*0.8),
            height: Math.round(window.innerHeight*0.7),
            start: "-PT3H",
            end: "P0D"
        },
        {
            name: "Lambda errors",
            metrics: [
                [ "AWS/Lambda", "Errors", "FunctionName", "nw3-api-AuthLambda-iWh1hvkAZCT1", "Resource", "nw3-api-AuthLambda-iWh1hvkAZCT1", { "period": 300 } ],
                [ "...", "nw3-api-ConnectLambda-zTxhufgzRUUl", ".", "nw3-api-ConnectLambda-zTxhufgzRUUl", { "period": 300 } ],
                [ "...", "nw3-api-DefaultLambda-rNEwgP2hFeii", ".", "nw3-api-DefaultLambda-rNEwgP2hFeii", { "period": 300 } ],
                [ "...", "nw3-api-DisconnectLambda-0L3Fx5G8Jin1", ".", "nw3-api-DisconnectLambda-0L3Fx5G8Jin1", { "period": 300 } ],
                [ "...", "nw3-api-EventFetchLambda-chcTjQcHY0RW", ".", "nw3-api-EventFetchLambda-chcTjQcHY0RW", { "period": 300 } ],
                [ "...", "nw3-api-GraphLambda-f0fjWBdIbQdk", ".", "nw3-api-GraphLambda-f0fjWBdIbQdk", { "period": 300 } ],
                [ "...", "nw3-api-PositionLambda-QJLiNWIInl2B", ".", "nw3-api-PositionLambda-QJLiNWIInl2B", { "period": 300 } ],
                [ "...", "nw3-api-VehicleFetchLambda-dO9FsLRBlcRq", ".", "nw3-api-VehicleFetchLambda-dO9FsLRBlcRq", { "period": 300 } ],
                [ "...", "nw3-infra-fmp-processor-GeohashFilter-4gtpg6TtQEXg", ".", "nw3-infra-fmp-processor-GeohashFilter-4gtpg6TtQEXg", { "period": 300 } ],
                [ "...", "nw3-infra-fmp-processor-MapMatcher-bq2HZ3B7rwNb", ".", "nw3-infra-fmp-processor-MapMatcher-bq2HZ3B7rwNb", { "period": 300 } ],
                [ "...", "nw3-infra-fmp-processor-RoadStatusUpdater-6vLHr7u7yy9q", ".", "nw3-infra-fmp-processor-RoadStatusUpdater-6vLHr7u7yy9q", { "period": 300 } ],
                [ "...", "nw3-messagedecoder-DenmDecoder-YmrvnP2H0QZs", ".", "nw3-messagedecoder-DenmDecoder-YmrvnP2H0QZs", { "period": 300 } ],
                [ "...", "nw3-sit-aware-SnapLambda-83Yl1iv7nE7Y", ".", "nw3-sit-aware-SnapLambda-83Yl1iv7nE7Y", { "period": 300 } ]
            ],
            legend: {
                position: "right"
            },
            title: "Lambda Errors: Sum",
            view: "timeSeries",
            stacked: false,
            stat: "Sum",
            width: Math.round(window.innerWidth*0.8),
            height: Math.round(window.innerHeight*0.7),
            start: "-PT72H",
            end: "P0D"
        },
        {
            name: "Event Count Statistics",
            width: Math.round(window.innerWidth*0.8),
            height: Math.round(window.innerHeight*0.7),
            view: "timeSeries",
            stacked: false,
            stat: "Sum",
            period: 300,
            title: "Events Count by Pub-id",
            yAxis: {
                left: {
                    min: 0
                }
            },
            start: "-PT6H",
            end: "P0D",
            metrics: [
                [ "NordicWay3", "EventCount", "EventVehicleStats", "SE90003" ],
                [ "...", "SE00010" ],
                [ "...", "SE90001" ],
                [ "...", "SE90006" ],
                [ "...", "SE90009" ],
                [ "...", "SE90010" ],
            ],
        },
        {
            name: "Scania Vehicles Count Statistics",
            width: Math.round(window.innerWidth*0.8),
            height: Math.round(window.innerHeight*0.7),
            view: "timeSeries",
            stacked: false,
            stat: "Sum",
            period: 300,
            title: "Matched Vehicle Count by Pub-id",
            yAxis: {
                left: {
                    min: 0
                }
            },
            start: "-PT6H",
            end: "P0D",
            metrics: [
                [ "NordicWay3", "VehicleCount", "EventVehicleStats", "SE90003" ],
                [ "...", "SE00010" ],
                [ "...", "SE90001" ],
                [ "...", "SE90006" ],
                [ "...", "SE90009" ],
                [ "...", "SE90010" ],
            ],
        },
        {
            name: "Event Vehicles Statistics",
            width: Math.round(window.innerWidth*0.8),
            height: Math.round(window.innerHeight*0.7),
            view: "timeSeries",
            stacked: false,
            stat: "Sum",
            period: 300,
            title: "Matched Vehicle Count by Pub-id",
            yAxis: {
                left: {
                    min: 0
                }
            },
            start: "-PT6H",
            end: "P0D",
            metrics: [
                [ "NordicWay3", "VehicleCount", "EventVehicleStats", "SE90003" ],
                [ "NordicWay3","EventCount","EventVehicleStats", "SE90003" ]
            ],
        }
    ]


    const getData = (def=null) =>{
        /* when using state the change does not come trough immediate*/
        console.log("Graphs: fetching data");
        if (def === null){
            def = graphs[selectedGraph]
        }
        def["start"]=selectedTime;
        def["period"]=periods[selectedTime];
        if (graphs[selectedGraph]["name"]==="Event Vehicles Statistics"){
            def["metrics"] = def["metrics"].map((o,idx)=>(o.slice(0,-1).concat(pubids[selectedPubId])))
            def["title"]=def["title"] + " " + pubids[selectedPubId]
        }
        if (!def["title"].includes(periods[selectedTime]) && def["name"] !== "Scania Vehicles"){
            def["title"]=def["title"] + " per " + periods[selectedTime] + "s";
        }

        console.log("Selected: ",selectedGraph)
        console.log(`Graph def=${JSON.stringify(def)}`)
        sendMessage(`{"action":"graph","graphdef":"${Buffer.from(JSON.stringify(def)).toString('base64')}"}`)
    }

    useEffect(()=>{
        getData();
        const timerId = setInterval(() => {
            getData();
          }, 5*60 * 1000);
          return () => clearInterval(timerId);
    },[selectedGraph,selectedTime,selectedPubId])


    return (
        <>
        <div className="sdds-row">
        <div className="no-padding sdds-col-sm-3 sdds-col-md-3 sdds-col-lg-3 sdds-col-xlg-3">
          <div className="sdds-dropdown" >
            <span className="sdds-dropdown-label-outside">Select type of graphs</span>
            <select
              name="transport"
              id="nativeSelect"
              value={selectedGraph}
              onChange={e => setSelectedGraph(e.target.value)}
            >
            {graphs.map((o,idx) => ( <option key={idx} value={idx}>{o.name}</option>))}
            </select>
          </div>
        </div>
        {graphs[selectedGraph]["name"]==="Event Vehicles Statistics"
            ?<div className="no-padding sdds-col-sm-3 sdds-col-md-3 sdds-col-lg-3 sdds-col-xlg-3">
            <div className="sdds-dropdown" >
              <span className="sdds-dropdown-label-outside">Select publisher id</span>
              <select
                name="pubid"
                id="pubSelect"
                value={selectedPubId}
                onChange={e => setSelectedPubId(e.target.value)}
              >
              {pubids.map((o,idx) => ( <option key={idx} value={idx}>{o}</option>))}
              </select>
            </div>
          </div>
            :null}
        <div className="no-padding sdds-col-sm-3 sdds-col-md-3 sdds-col-lg-3 sdds-col-xlg-3">
          <div className="sdds-dropdown" >
            <span className="sdds-dropdown-label-outside">Select time interval</span>
            <select
              name="timeperios"
              id="nativeSelect2"
              value={selectedTime}
              onChange={e => setSelectedTime(e.target.value)}
            >
            {timePeriod.map((o,idx) => (
            <option key={idx} value={o}>{o}</option>
            ))}

            </select>
          </div>
        </div>
        </div>
        {graphsMessage ? <img src={`data:image/png;base64,${graphsMessage}`}/>:<div>No data</div>}

        </>
    );
};
export default Graphs;