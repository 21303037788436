import React from 'react';

import SettingsParameter from './SettingsParameter.js';

const SettingsParagraph = ({path,settings}) =>{
    return(
    <>
        <div class="sdds-row">
          <div class="sdds-col-max-12"><h2>{path}</h2></div>
        </div>
      {Object.keys(settings[path]).map((key2, index2) =>{
            return(
              <div className="component-wrapper" style={{ width: "400px" }}>
            <SettingsParameter name={key2} path={path + "."+key2} />
            </div>
            )
      })
      }
    </>
      )
}

export default SettingsParagraph;
