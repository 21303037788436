import "./Footer.css";

const Footer = () => {
  return (
    <div className="sdds-footer">
      <div className="sdds-footer-main">
        <div className="sdds-footer-main-brand">
          <p>Copyright &copy; 2023 Scania, build time {process.env.REACT_APP_BUILD_TIME}</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
