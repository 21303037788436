import {useWebSocketContext}  from "../../contexts/websocketContext";
import { DisplayMapClass } from "../map/HereMap";
import {usePosition} from "../../contexts/positionContext";

const Location = () => {
  const { snapMessage } = useWebSocketContext();
  const { position,simPositionList } = usePosition();
  return (
    <>
      <div className="section-wrapper sdds-col-xlg-8 sdds-col-md-4 sdds-col-sm-4">
        <h5 className="sdds-headline-05">Current location</h5>
      </div>
      <div className="component-wrapper">
        <table className="sdds-table sdds-table--compact">
          <thead className="sdds-table__header">
            <tr className="sdds-table__row">
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Variable</th>
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Value</th>
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Unit</th>
            </tr>
          </thead>
          <tbody className="sdds-table__body">
            <tr className="sdds-table__row">
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">Latitude</td>
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{position.lat.toFixed(3)}</td>
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">degrees</td>
            </tr>
            <tr className="sdds-table__row">
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">Longitude</td>
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{position.lon.toFixed(3)}</td>
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">degrees</td>
            </tr>
            <tr className="sdds-table__row">
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">Timestamp</td>
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{position.timestamp.toFixed(3)}</td>
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">ms</td>
            </tr>
            <tr className="sdds-table__row">
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">Accuracy</td>
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{position.accuracy.toFixed(1)}</td>
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">m</td>
            </tr>
            <tr className="sdds-table__row">
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">Heading</td>
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{position.heading.toFixed(3)}</td>
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">degrees</td>
            </tr>
            <tr className="sdds-table__row">
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">Speed</td>
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{position.speed.toFixed(3)}</td>
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">m/s</td>
            </tr>
            <tr className="sdds-table__row">
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">Geohash</td>
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{position.geohash}</td>
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">-</td>
            </tr>
            <tr className="sdds-table__row">
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">Source</td>
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{position.source}</td>
              <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">-</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="component-wrapper">
        <h5>Snap to road data</h5>
        <table className="sdds-table sdds-table--compact">
          <thead className="sdds-table__header">
            <tr className="sdds-table__row">
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Variable</th>
              <th className="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(snapMessage).map((keyname, idx) => (
                    <tr className="sdds-table__row" key={idx}>
                    <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{keyname}</td>
                    <td className="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{typeof snapMessage[keyname]==='object'?JSON.stringify(snapMessage[keyname]):
                        typeof snapMessage[keyname]==='boolean'? snapMessage[keyname]?"True":"False":
                        snapMessage[keyname]}
                    </td>
                    </tr>
                ))}
          </tbody>
        </table>
      </div>
      <DisplayMapClass route={simPositionList.current} mypos={[position.lat, position.lon]}/>
    </>
  );
};
export default Location;
