import { useState, useContext, createContext, useEffect } from "react";
import { useWakeLock } from "react-screen-wake-lock";

export const SettingsContext = createContext(null);
const localFileName = 'settings_v6'
const defaultSettings = {
  general:{
    keepScreenAlive: {type:'bool',value:true,text:"Keep screen alive"},
    actOnResize: {type:'bool',value:false,text:"React on resize, effects the dashboard, problems on mobile devices if on"},
  },
  connection:{
    sendDataViaWS: {type:'bool',value:true,text:"Send data to backend"},
    sendInterval: {type:'int',value:60,text:"Interval in seconds",min:1,max:120,step:1, unit:"s"},
    messagesInHistoryWS: {type:'int',value:15,text: "Message to keep in history",min:1,max:200,step:1,unit:"-"},
  },
  dashboard:{
    showDevParameters:{type:'bool',value:false,text:"Show develop parameters"},
    drawInfoDisplayDebug:{type:'bool',value:false,text:"Show debug info for warning display"},
    drawLaneDebug:{type:'bool',value:false,text:"Show Lane drawing debug lines"},
    drawLaneInfoDebug:{type:'bool',value:false,text:"Show Lane information debug info"},
    maxSpeedVehicle: {type:'int',value:90,text:"Maximum speed of vehicle",min:30,max:130,step:10,unit:"km/h"},
    warningDisplayTimeOut:{type:'int',value:20,text:"Time to display received warning",min:1,max:60,step:1,unit:"s"},
    laneCountingFromLeft:{type:'bool',value:false,text:"Start lane number 1 from left instead of right"},
  },
  location:{
    useSimulator: {type:'bool',value:true,text:"Simulate position"},
    simStart:{type:"str",value:"u6scfd3d4exq",text:"Start position in geohash (moraberg u6s8f0emv3j0)"},
    useRoute: {type:'bool',value:false,text:"Create a route by using endpoint in next field"},
    simEnd: {type:"str",value:"u6sfhmyp4j6q",text:"End position in geohash, only used if route is selected above (u6knzd3br)"},
    simEpsg: {type: "str", value:"3006",text:"EPSG number"},
    simSpeedMs: {type:"int", value:25, text:"Speed of simulator", min: 1,max:250,step:10,unit:"m/s"},
    simSampleTime: {type:'int',value:5, text: "Time between simulator updates", min:1,max:120,step:1,unit:"s"},
  },
  map:{
    mapZoomLevel: {type:"int", value:16, text:"Zoom level", min: 1,max:20,step:1,unit:"-"},
    eventFilter:{type: "str", value:"SE",text:"Filter publisher id starting with this string"},
    fetchExternalData:  {type:'bool',value:false,text:"Fetch external data (not interchange)"},
    eventFetcher: {type:"int", value:60, text:"Fetch external data at this interval", min: 30,max:300,step:30,unit:"s"},
    clusteringEps: {type:"int", value:32, text:"Maximum radius of the neighbourhood",min:1,max:200,step:1,unit:"-"},
    clusteringMinWeight: {type:"int", value:10, text:"Minimum weight of points required to form a cluster",min:1,max:200,step:1,unit:"-"}
  },
};

export function SettingsProvider(props) {
  const [currentSettings, setSettings] = useState(() => {
    try {
      return {...defaultSettings,...JSON.parse(localStorage.getItem(localFileName))} ?? defaultSettings;
    } catch {
      return defaultSettings;
    }
  });

  const setToDefaultSettings = ()=>{
    setSettings(defaultSettings);
  }

  const { isSupported, released, request, release } = useWakeLock({
    onRequest: () => console.log("Screen Wake Lock: requested!"),
    onError: () => console.log("An error happened "),
    onRelease: () => console.log("Screen Wake Lock: released!"),
  });

  const contextValue = {
    currentSettings,
    setSettings,
    setToDefaultSettings
  };

  useEffect(() => {
    console.log("Settings",isSupported ? "Awake supported":"Awake not supported")
    if (isSupported && released !== currentSettings.general.keepScreenAlive.value) {
      console.log(
        "Settings",
        "Updating keep screen alive status to " +
        currentSettings.general.keepScreenAlive.value
      );
      currentSettings.general.keepScreenAlive.value=== true ? request() : release();
    }
  }, [currentSettings.general.keepScreenAlive.value,release,request,isSupported,released]);

  useEffect(()=>{
    localStorage.setItem(localFileName, JSON.stringify(currentSettings));
  }, [currentSettings])

  return (
    <SettingsContext.Provider value={contextValue}>
      {props.children}
    </SettingsContext.Provider>
  );
}

export function useSettings() {
  return useContext(SettingsContext);
}