import { useUserContext } from "../../contexts/userContext";

const Information = () => {
  const { handleLogout } = useUserContext();

  return (
    <>
        <div>This is the Scania Nordic Way 3 HMI</div>
        <div class="sdds-divider-coloured" style={{margin: "5px",width: "400px"}} ></div>
        <li>This application uses your position to simulate driving in a vehicle</li>
        <li>Your position is temporarily stored in the backend</li>
        <li>The position storage is encrypted and secured</li>
        <li>Under settings there is the possibility to use a GPS simulator</li>
        <li>Currently it only puts you close to the Södertälje E4 bridge</li>
        <div class="sdds-divider-coloured" style={{margin: "5px",width: "400px"}} ></div>
        <li>Data is send over a secure websocket</li>
        <li>Your settings are saved as cookie in your local webbrowser</li>
        <li>This application is primarily made for being run on Ipad</li>
        <li>You might experience resizing issues when running on smaller screens</li>
        <br></br>
        <div class="sdds-divider-coloured" style={{margin: "5px",width: "400px"}} ></div>
        <br></br>
        <button onClick={handleLogout}>Logout</button>
        <div className="sdds-headline-01">
            <link
              href="https://cdn.digitaldesign.scania.com/icons/dist/1.0.0/fonts/css/sdds-icons.css"
              rel="stylesheet"
            />
            <i className="sdds-icon scania-bus"></i>
            <i className="sdds-icon scania-truck"></i>
          </div>
    </>
  );
};
export default Information;
